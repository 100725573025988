import { motion } from 'framer-motion';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

export default function ServicesSection() {
  const { t } = useTranslation('common');

  const Service = ({
    href,
    title,
    desc,
  }: {
    href: string;
    title?: string;
    desc?: string;
  }) => {
    return (
      <motion.div
        variants={{
          hidden: { y: 40, opacity: 0 },
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          },
        }}
      >
        <Link href={href}>
          <div className="scroll-margin-top group relative col-span-2 rounded-xl border p-5 shadow-sm hover:cursor-pointer hover:bg-slate-50 md:col-span-1 md:p-8">
            <div>
              <span className="text-gradient text-lg font-bold md:text-xl xl:text-2xl">
                {title}
              </span>
              <p className="mt-2 line-clamp-3 font-medium text-gray-700 md:text-lg">
                {desc}
              </p>
            </div>
          </div>
        </Link>
      </motion.div>
    );
  };

  return (
    <section id="services" className="scroll-margin-top bg-white py-10">
      <div className="relative mx-auto p-5 sm:container xl:max-w-screen-xl">
        <motion.div
          variants={{
            hidden: { y: 40, opacity: 0 },
            visible: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <div>
            <span className="text-gradient mb-3 inline-block text-lg font-semibold ">
              {t('services.service')}
            </span>
          </div>
          <div>
            <h2 className="text-gradient mb-5 inline-block">
              {t('services.title')}
            </h2>
          </div>
          <div>
            <p className="mb-10 max-w-xl font-medium italic text-gray-700 sm:leading-8">
              {t('services.subtitle')}
            </p>
          </div>
        </motion.div>
        <motion.div
          className="grid grid-cols-1 gap-5 lg:grid-cols-2"
          variants={{
            hidden: { opacity: 1 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1,
              },
            },
          }}
          initial="hidden"
          whileInView="visible"
        >
          <Service
            href="/solutions-consultancy/"
            title={`${t('services.consultancy.title')}`}
            desc={`${t('services.consultancy.desc')}`}
          />
          <Service
            href="/software-development/"
            title={`${t('services.development.title')}`}
            desc={`${t('services.development.desc')}`}
          />
          <Service
            href="/web-design/"
            title={`${t('services.webdesign.title')}`}
            desc={`${t('services.webdesign.desc')}`}
          />
          <Service
            href="/software-it-services/"
            title={`${t('services.softwareservices.title')}`}
            desc={`${t('services.softwareservices.desc')}`}
          />
        </motion.div>
      </div>
    </section>
  );
}
