import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export interface PricingPlanProps {
  title: string;
  desc: string;
  price: string;
  features: string[];
}

export default function PricingPlan({
  title,
  desc,
  price,
  features,
}: PricingPlanProps) {
  const { t } = useTranslation('common');
  return (
    <div className="flex w-full max-w-md flex-col rounded-3xl border-4 border-gray-200 bg-white px-5 py-10 shadow-2xl shadow-primary/20">
      <div className="flex w-full flex-col items-center justify-center p-2 text-center">
        <span className="text-gradient text-2xl font-bold">{title}</span>
        <span className="text-gradient mt-2 text-4xl font-bold">{price}</span>
        <span className="mt-2 text-sm text-gray-700 lg:h-16">{desc}</span>
      </div>
      <div className="mt-2 grow">
        <ul>
          {features.map((item, index) => (
            <li key={index}>
              <div className="mt-5 flex items-center gap-5">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="bg-gradient aspect-square self-start rounded-full p-1 font-bold text-white"
                />
                <span className="font-medium text-gray-700">{item}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-20 flex items-end justify-center">
        <Link href="#contact" scroll={false}>
          <button className="bg-gradient self-center rounded-md px-4 py-2 font-bold text-white hover:brightness-125">
            {t('hero.contactusnow')}
          </button>
        </Link>
      </div>
    </div>
  );
}
