import { useTranslation } from 'next-i18next';

import PricingSection from '../pricings/PricingSection';

export default function ConsultancyPricingSection() {
  const { t } = useTranslation('common');
  return (
    <PricingSection
      plans={[
        {
          title: t(`services.consultancy.pricing.basic.title`),
          desc: t(`services.consultancy.pricing.basic.desc`),
          price: t(`services.consultancy.pricing.basic.price`),
          features: [
            t(`services.consultancy.pricing.basic.feature1`),
            t(`services.consultancy.pricing.basic.feature2`),
            t(`services.consultancy.pricing.basic.feature3`),
            t(`services.consultancy.pricing.basic.feature4`),
          ],
        },
        {
          title: t(`services.consultancy.pricing.pro.title`),
          desc: t(`services.consultancy.pricing.pro.desc`),
          price: t(`services.consultancy.pricing.pro.price`),
          features: [
            t(`services.consultancy.pricing.pro.feature1`),
            t(`services.consultancy.pricing.pro.feature2`),
            t(`services.consultancy.pricing.pro.feature3`),
            t(`services.consultancy.pricing.pro.feature4`),
            t(`services.consultancy.pricing.pro.feature5`),
            t(`services.consultancy.pricing.pro.feature6`),
            t(`services.consultancy.pricing.pro.feature7`),
          ],
        },
      ]}
    />
  );
}
