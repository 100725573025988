import type { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import AboutSection from '@/components/sections/AboutSection';
import ConsultancyPricingSection from '@/components/sections/ConsultancyPricingSection';
import ContactSection2 from '@/components/sections/ContactSection2';
import DevelopmentPricingSection from '@/components/sections/DevelopmentPricingSection';
import HeroSection from '@/components/sections/HeroSection';
import ServicesSection from '@/components/sections/ServicesSection';
import SoftwareITServicesPricingSection from '@/components/sections/SoftwareITServicesPricingSection';
import ConsultancyService from '@/components/services/ConsultancyService';
import SoftwareAndITService from '@/components/services/SoftwareAndITService';
import SoftwareDevelopmentService from '@/components/services/SoftwareDevelopmentService';
import WebDesignService from '@/components/services/WebDesignService';
import LandingPageTemplate from '@/templates/LandingPage';
import { PAGE_URL } from '@/utils/constants';

export const getStaticProps = (async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'en', ['common'])),
    },
    revalidate: 10,
  };
}) satisfies GetStaticProps<{}>;

const Index = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return (
    <LandingPageTemplate
      seo={{
        title: t('seo.home.title').toString(),
        description: t('seo.home.description').toString(),
        canonical: `${PAGE_URL}${locale}/`,
        openGraph: {
          type: 'website',
          url: PAGE_URL,
          title: t('seo.home.title').toString(),
          description: t('seo.home.description').toString(),
          locale,
          images: [
            {
              url: `${PAGE_URL}assets/images/meta-image.jpg`,
              alt: 'Rizerssoft meta image alt',
            },
          ],
        },
      }}
    >
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <ConsultancyService />
      <ConsultancyPricingSection />
      <SoftwareDevelopmentService />
      <DevelopmentPricingSection />
      <WebDesignService />
      <SoftwareAndITService />
      <SoftwareITServicesPricingSection />
      {/* <SolutionsSection /> */}
      <ContactSection2 />
    </LandingPageTemplate>
  );
};

export default Index;
