import { useTranslation } from 'next-i18next';

import { DevelopmentImage } from '@/assets';

import Service from '../common/Service';

export default function SoftwareDevelopmentService() {
  const { t } = useTranslation('common');
  return (
    <Service
      id="development"
      href="/software-development/"
      title={`${t('services.development.title')}`}
      desc={`${t('services.development.desc')}`}
      image={DevelopmentImage}
      features={[
        `${t('services.development.feature1')}`,
        `${t('services.development.feature2')}`,
        `${t('services.development.feature3')}`,
        `${t('services.development.feature4')}`,
      ]}
      reverse
    />
  );
}
