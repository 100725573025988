import { useTranslation } from 'next-i18next';

import { WebDesignImage } from '@/assets';

import Service from '../common/Service';

export default function WebDesignService() {
  const { t } = useTranslation('common');
  return (
    <Service
      id="webdesign"
      href="/web-design/"
      title={`${t('services.webdesign.title')}`}
      desc={`${t('services.webdesign.desc')}`}
      image={WebDesignImage}
      features={[
        `${t('services.webdesign.feature1')}`,
        `${t('services.webdesign.feature2')}`,
        `${t('services.webdesign.feature3')}`,
        `${t('services.webdesign.feature4')}`,
      ]}
    />
  );
}
