import { motion } from 'framer-motion';

import type { PricingPlanProps } from './PricingPlan';
import PricingPlan from './PricingPlan';

export default function PricingSection({
  plans,
}: {
  plans: PricingPlanProps[];
}) {
  return (
    <section id="services" className="scroll-margin-top bg-bgsecondary py-5">
      <motion.div
        className="relative mx-auto p-5 sm:container xl:max-w-screen-xl"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.7,
            },
          },
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-2">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="flex justify-center lg:odd:justify-end lg:even:justify-start"
            >
              <PricingPlan {...plan} />
            </div>
          ))}
        </div>
      </motion.div>
    </section>
  );
}
