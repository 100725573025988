import { motion } from 'framer-motion';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import {
  ConsultancyImage,
  DevelopmentImage,
  SoftwareServicesImage,
  WebDesignImage,
} from '@/assets';

const services = [
  {
    id: 'consultancy',
    title: 'services.consultancy.title',
    image: ConsultancyImage,
  },
  {
    id: 'development',
    title: 'services.development.title',
    image: DevelopmentImage,
  },
  {
    id: 'webdesign',
    title: 'services.webdesign.title',
    image: WebDesignImage,
  },
  {
    id: 'softwareservices',
    title: 'services.softwareservices.title',
    image: SoftwareServicesImage,
  },
];

const ServiceIcon = ({
  id,
  title,
  image,
}: {
  id: string;
  title: string;
  image: StaticImageData;
}) => {
  const { t } = useTranslation('common');
  return (
    <Link href={`/#${id}`} scroll={false}>
      <motion.div
        className="relative flex aspect-square flex-col items-center justify-end overflow-hidden rounded-3xl hover:cursor-pointer"
        variants={{
          hidden: { y: 20, opacity: 0, scale: 0 },
          visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
              duration: 0.5,
            },
          },
        }}
      >
        <Image
          src={image}
          alt={id}
          fill
          priority
          fetchPriority="high"
          sizes="248px 248px"
          className="object-cover object-center"
        />
        <div className="z-10 w-full px-1 py-2 text-center backdrop-blur">
          <span className="text-center text-lg font-bold text-white">
            {t(title)}
          </span>
        </div>
      </motion.div>
    </Link>
  );
};

export default function HeroSection() {
  const { t } = useTranslation('common');

  return (
    <section id="home" className="bg-gradient relative w-full">
      <div className="absolute h-screen w-screen" />
      <div className="relative m-auto flex flex-col-reverse gap-10 p-5 pb-20 pt-32 sm:container lg:flex-row lg:gap-20 lg:pt-40 xl:max-w-screen-xl xl:pt-48">
        <motion.div
          className="box-border grid aspect-square w-full max-w-lg grid-cols-2 items-center justify-center gap-4 xl:gap-8"
          variants={{
            hidden: { opacity: 1 },
            visible: {
              opacity: 1,
              transition: {
                delayChildren: 0.2,
                staggerChildren: 0.2,
              },
            },
          }}
          initial="hidden"
          animate="visible"
        >
          {services.map((service, index) => (
            <ServiceIcon key={index} {...service} />
          ))}
        </motion.div>
        <div className="box-border max-w-xl rounded-3xl py-5 xl:max-w-2xl">
          <motion.div
            variants={{
              hidden: { y: 40, opacity: 0 },
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.5,
                },
              },
            }}
            initial="hidden"
            animate="visible"
          >
            <h1 className="mb-8 text-3xl font-bold leading-10 text-white md:text-4xl xl:text-5xl xl:leading-[4rem]">
              {t('hero.title')}
            </h1>
          </motion.div>
          <motion.div
            variants={{
              hidden: { y: 40, opacity: 0 },
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.7,
                  duration: 0.5,
                },
              },
            }}
            initial="hidden"
            animate="visible"
          >
            <p className="mb-8 text-gray-200 md:text-lg lg:text-xl">
              {t('hero.content')}
            </p>
          </motion.div>
          <motion.div
            variants={{
              hidden: { y: 40, opacity: 0 },
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 1,
                  duration: 0.5,
                },
              },
            }}
            initial="hidden"
            animate="visible"
          >
            <Link
              href="/contact"
              scroll={false}
              className="inline-block rounded bg-bgsecondary px-6 py-2 text-lg font-bold text-gray-700 shadow-md hover:text-primary"
            >
              {t('hero.contactusnow')}
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
