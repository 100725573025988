import Head from 'next/head';
import { useRouter } from 'next/router';
import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import type { HTMLProps } from 'react';
import React from 'react';

import Footer from '@/components/footer/Footer';
import Header from '@/components/headers/Header';
import { PAGE_URL } from '@/utils/constants';

export interface ILandingPageTemplateProps extends HTMLProps<HTMLDivElement> {
  seo?: NextSeoProps;
}

export default function LandingPageTemplate({
  seo,
  children,
}: ILandingPageTemplateProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href={`${router.basePath}/android-chrome-512x512.png`}
          key="icon512"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${router.basePath}/android-chrome-192x192.png`}
          key="icon192"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
      </Head>
      <NextSeo
        {...{
          ...seo,
          defaultTitle: 'Rizerssoft',
          languageAlternates: [
            {
              hrefLang: 'en',
              href: `${PAGE_URL}en/`,
            },
            {
              hrefLang: 'vi',
              href: `${PAGE_URL}vi/`,
            },
          ],
          additionalMetaTags: [
            {
              name: 'mobile-web-app-capable',
              content: 'yes',
            },
            {
              name: 'apple-mobile-web-app-capable',
              content: 'yes',
            },
            {
              name: 'keywords',
              content:
                'Rizer, Rizers, Rizer soft, Rizers soft, Rizerssoft, Rizersoft, business, solution, solutions, technologies, technology, mobile app, web app, app development, consulting, digital, digital transform, digital transformation, vietnam, viet nam, vietnamese, ho chi minh, giai phap, dich vu, chuyen doi so, outsource, outsourcing, tu van giai phap',
            },
            {
              name: 'google',
              content: 'nositelinkssearchbox',
              keyOverride: 'sitelinks',
            },
            {
              name: 'google',
              content: 'notranslate',
              keyOverride: 'notranslate',
            },
          ],
          twitter: {
            cardType: 'summary_large_image',
          },
        }}
      />
      <Header />
      <main className="box-border overflow-hidden bg-transparent">
        {children}
      </main>
      <Footer />
    </>
  );
}
