import AboutImage from '@/public/assets/images/aboutus.webp';
import ConsultancyImage from '@/public/assets/images/consultancy.webp';
import DevelopmentImage from '@/public/assets/images/development.webp';
import SoftwareServicesImage from '@/public/assets/images/softwareservices.webp';
import WebDesignImage from '@/public/assets/images/webdesign.webp';

export {
  AboutImage,
  ConsultancyImage,
  DevelopmentImage,
  SoftwareServicesImage,
  WebDesignImage,
};
