import { useTranslation } from 'next-i18next';

import PricingSection from '../pricings/PricingSection';

export default function SoftwareITServicesPricingSection() {
  const { t } = useTranslation('common');
  return (
    <PricingSection
      plans={[
        {
          title: t(
            `services.softwareservices.pricing.enhancementtesting.title`
          ),
          desc: t(`services.softwareservices.pricing.enhancementtesting.desc`),
          price: t(
            `services.softwareservices.pricing.enhancementtesting.price`
          ),
          features: [
            t(`services.softwareservices.pricing.enhancementtesting.feature1`),
            t(`services.softwareservices.pricing.enhancementtesting.feature2`),
            t(`services.softwareservices.pricing.enhancementtesting.feature3`),
            t(`services.softwareservices.pricing.enhancementtesting.feature4`),
          ],
        },
        {
          title: t(
            `services.softwareservices.pricing.technicaloperations.title`
          ),
          desc: t(`services.softwareservices.pricing.technicaloperations.desc`),
          price: t(
            `services.softwareservices.pricing.technicaloperations.price`
          ),
          features: [
            t(`services.softwareservices.pricing.technicaloperations.feature1`),
            t(`services.softwareservices.pricing.technicaloperations.feature2`),
            t(`services.softwareservices.pricing.technicaloperations.feature3`),
            t(`services.softwareservices.pricing.technicaloperations.feature4`),
            t(`services.softwareservices.pricing.technicaloperations.feature5`),
            t(`services.softwareservices.pricing.technicaloperations.feature6`),
          ],
        },
      ]}
    />
  );
}
