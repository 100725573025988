import { useTranslation } from 'next-i18next';

import PricingSection from '../pricings/PricingSection';

export default function DevelopmentPricingSection() {
  const { t } = useTranslation('common');
  return (
    <PricingSection
      plans={[
        {
          title: t(`services.development.pricing.mvp.title`),
          desc: t(`services.development.pricing.mvp.desc`),
          price: t(`services.development.pricing.mvp.price`),
          features: [
            t(`services.development.pricing.mvp.feature1`),
            t(`services.development.pricing.mvp.feature2`),
            t(`services.development.pricing.mvp.feature3`),
            t(`services.development.pricing.mvp.feature4`),
            t(`services.development.pricing.mvp.feature5`),
          ],
        },
        {
          title: t(`services.development.pricing.enterprise.title`),
          desc: t(`services.development.pricing.enterprise.desc`),
          price: t(`services.development.pricing.enterprise.price`),
          features: [
            t(`services.development.pricing.enterprise.feature1`),
            t(`services.development.pricing.enterprise.feature2`),
            t(`services.development.pricing.enterprise.feature3`),
            t(`services.development.pricing.enterprise.feature4`),
            t(`services.development.pricing.enterprise.feature5`),
            t(`services.development.pricing.enterprise.feature6`),
            t(`services.development.pricing.enterprise.feature7`),
          ],
        },
      ]}
    />
  );
}
