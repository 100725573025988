import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export default function Service({
  id,
  href,
  title,
  desc,
  features,
  image,
  reverse,
}: {
  id: string;
  href?: string;
  title: string;
  desc: string;
  features: string[];
  image: StaticImageData;
  reverse?: boolean;
}) {
  const { t } = useTranslation('common');
  return (
    <section id={id} className="scroll-margin-top bg-bgsecondary py-5">
      <motion.div
        className="relative mx-auto p-5 sm:container xl:max-w-screen-xl"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.7,
            },
          },
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="flex flex-col">
          <div className="mt-10 box-border block overflow-hidden rounded-3xl bg-white text-left shadow first:mt-0 md:mt-14 lg:grid lg:grid-cols-7 xl:mt-20">
            <div
              className={`box-border px-5 py-10 lg:col-span-4 lg:p-12 xl:p-20 ${
                reverse ? '' : 'order-2'
              }`}
            >
              <div className="m-0 p-0">
                <span className="text-gradient mt-10 text-2xl font-bold">
                  {title}
                </span>
                <p className="mt-2 text-gray-700 lg:text-lg">{desc}</p>
                <ul className="mt-10">
                  {features.map((item, index) => (
                    <li key={index}>
                      <div className="mt-5 flex items-center gap-5">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="bg-gradient aspect-square self-start rounded-full p-1 font-bold text-white"
                        />
                        <span className="font-medium text-gray-700 lg:text-lg">
                          {item}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                {href && (
                  <Link href={href}>
                    <button className="bg-gradient mt-10 rounded-md px-4 py-2 font-bold text-white hover:brightness-125">
                      {t('services.learnaboutmore')}
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="h-full w-full lg:col-span-3">
              <Image
                src={image}
                alt={`${id}-image`}
                loading="lazy"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
