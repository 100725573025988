import { useTranslation } from 'next-i18next';

import { ConsultancyImage } from '@/assets';

import Service from '../common/Service';

export default function ConsultancyService() {
  const { t } = useTranslation('common');
  return (
    <Service
      id="consultancy"
      href="/solutions-consultancy/"
      title={`${t('services.consultancy.title')}`}
      desc={`${t('services.consultancy.desc')}`}
      image={ConsultancyImage}
      features={[
        `${t('services.consultancy.feature1')}`,
        `${t('services.consultancy.feature2')}`,
        `${t('services.consultancy.feature3')}`,
        `${t('services.consultancy.feature4')}`,
      ]}
    />
  );
}
