import { useTranslation } from 'next-i18next';

import { SoftwareServicesImage } from '@/assets';

import Service from '../common/Service';

export default function SoftwareAndITService() {
  const { t } = useTranslation('common');
  return (
    <Service
      id="softwareservices"
      href="/software-it-services/"
      title={`${t('services.softwareservices.title')}`}
      desc={`${t('services.softwareservices.desc')}`}
      image={SoftwareServicesImage}
      features={[
        `${t('services.softwareservices.feature1')}`,
        `${t('services.softwareservices.feature2')}`,
        `${t('services.softwareservices.feature3')}`,
        `${t('services.softwareservices.feature4')}`,
      ]}
      reverse
    />
  );
}
