import { motion } from 'framer-motion';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { AboutImage } from '@/assets';

export default function AboutSection() {
  const { t } = useTranslation('common');
  return (
    <section className="bg-bgsecondary py-10">
      <div className="mx-auto px-5 sm:container xl:max-w-screen-xl">
        <div
          id="aboutus"
          className="scroll-margin-top block border border-black/10 bg-white shadow-md lg:grid lg:grid-cols-2"
        >
          <motion.div
            className="relative px-5 py-8 sm:px-10 sm:py-16 xl:p-16"
            variants={{
              hidden: { opacity: 1 },
              visible: {
                opacity: 1,
                transition: {
                  delayChildren: 0.5,
                  staggerChildren: 0.1,
                },
              },
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <div className="m-0 p-0">
              <motion.span
                className="bg-gradient mb-5 inline-block rounded-md px-5 py-2 font-semibold text-white"
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                    },
                  },
                }}
              >
                {t('aboutus.aboutus')}
              </motion.span>
              <motion.h2
                className="text-gradient mb-8"
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                    },
                  },
                }}
              >
                {t('aboutus.title')}
              </motion.h2>
              <motion.p
                className="mb-10 text-gray-700 md:text-lg"
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                    },
                  },
                }}
              >
                {t('aboutus.content1')}
              </motion.p>
              <motion.p
                className="mb-10 text-gray-700 md:text-lg"
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                    },
                  },
                }}
              >
                {t('aboutus.content2')}
              </motion.p>
            </div>
          </motion.div>
          <motion.div
            className="h-full w-full"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.7,
                },
              },
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <Image
              src={AboutImage}
              loading="lazy"
              alt="about-image"
              className="h-full w-full object-cover object-center"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}
